import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import { H1, H2, P } from '../components';

export default function Privacy() {
  return (
    <Layout title="Privacy Policy" noIndex>
      <div className="w-full px-10 pt-32 md:pt-40 lg:pt-60 flex flex-col">
        <div className="m-auto">
          <H1>Privacy Policy</H1>
          <P big textSmaller>
            At Loka Brew Works, accessible from lokabrews.com, one of our main priorities is the privacy of our
            visitors. This Privacy Policy document contains types of information that is collected and recorded by Loka
            Brew Works and how we use it. If you have additional questions or require more information about our Privacy
            Policy, do not hesitate to contact us. This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information that they shared and/or collect in Loka
            Brew Works. This policy is not applicable to any information collected offline or via channels other than
            this website.
          </P>
          <H2 className="mt-5">Consent</H2>
          <P big textSmaller>
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
          </P>
          <H2 className="mt-5">Information we collect</H2>
          <P big textSmaller>
            The personal information that you are asked to provide, and the reasons why you are asked to provide it,
            will be made clear to you at the point we ask you to provide your personal information. If you contact us
            directly, we may receive additional information about you such as your name, email address, the contents of
            the message and/or attachments you may send us, and any other information you may choose to provide. When
            you sign up for our newsletter, we may ask for your email address.
          </P>
          <H2 className="mt-5">How we use your information</H2>
          <P big textSmaller>
            We use the information we collect in various ways, including to:
            <ul className="list-disc list-inside">
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>Develop new products, services, features, and functionality</li>
              <li>
                Communicate with you, either directly or through one of our partners, including for customer service, to
                provide you with updates and other information relating to the website, and for marketing and
                promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>
          </P>
          <H2 className="mt-5">Log Files</H2>
          <P big textSmaller>
            Loka Brew Works follows a standard procedure of using log files. These files log visitors when they visit
            websites. All hosting companies do this and a part of hosting services&#39; analytics. The information
            collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider
            (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to
            any information that is personally identifiable. The purpose of the information is for analyzing trends,
            administering the site, tracking users&#39; movement on the website, and gathering demographic information.
          </P>
          <H2 className="mt-5">Cookies and Web Beacons</H2>
          <P big textSmaller>
            Like any other website, Loka Brew Works uses &#39;cookies&#39;. These cookies are used to store information
            including visitors&#39; preferences, and the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users&#39; experience by customizing our web page content based on
            visitors&#39; browser type and/or other information.
          </P>
          <H2 className="mt-5">Advertising Partners Privacy Policies</H2>
          <P big textSmaller>
            You may consult this list to find the Privacy Policy for each of the advertising partners of Loka Brew
            Works. Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that
            are used in their respective advertisements and links that appear on Loka Brew Works, which are sent
            directly to users&#39; browser. They automatically receive your IP address when this occurs. These
            technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the
            advertising content that you see on websites that you visit. Note that Loka Brew Works has no access to or
            control over these cookies that are used by third-party advertisers.
          </P>
          <H2 className="mt-5">Third Party Privacy Policies</H2>
          <P big textSmaller>
            Loka Brew Works&#39;s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising
            you to consult the respective Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about how to opt-out of certain options. You
            can choose to disable cookies through your individual browser options. To know more detailed information
            about cookie management with specific web browsers, it can be found at the browsers&#39; respective
            websites.
          </P>
          <H2 className="mt-5">CCPA Privacy Rights (Do Not Sell My Personal Information)</H2>
          <P big textSmaller>
            Under the CCPA, among other rights, California consumers have the right to: Request that a business that
            collects a consumer&#39;s personal data disclose the categories and specific pieces of personal data that a
            business has collected about consumers. Request that a business delete any personal data about the consumer
            that a business has collected. Request that a business that sells a consumer&#39;s personal data, not sell
            the consumer&#39;s personal data. If you make a request, we have one month to respond to you. If you would
            like to exercise any of these rights, please contact us.
          </P>
          <H2 className="mt-5">GDPR Data Protection Rights</H2>
          <P big textSmaller>
            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled
            to the following: The right to access – You have the right to request copies of your personal data. We may
            charge you a small fee for this service. The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the right to request that we complete the
            information you believe is incomplete. The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions. The right to restrict processing – You have the right to
            request that we restrict the processing of your personal data, under certain conditions. The right to object
            to processing – You have the right to object to our processing of your personal data, under certain
            conditions. The right to data portability – You have the right to request that we transfer the data that we
            have collected to another organization, or directly to you, under certain conditions. If you make a request,
            we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
          </P>
          <H2 className="mt-5">Individuals under the Age of 21</H2>
          <P big textSmaller noSpacing>
            This Website is not intended for anyone under 21 years of age. No one under age 21 may provide any
            information to or on the Website. We do not knowingly collect personal information from anyone under 21. If
            you are under 21, do not use or provide any information on this Website or on or through any of its
            features/register on the Website, make any purchases through the Website, use any of the interactive or
            public comment features of this Website or provide any information about yourself to us, including your
            name, e-mail address or any screen name or user name you may use. If we learn we have collected or received
            personal information from anyone under 21, we will delete that information. If you believe we might have any
            information from or about anyone under 21, please contact us at care@lokabrews.com
          </P>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
